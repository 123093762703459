<template>
  <teleport to="#LoginContent">
    <div class="nk-block mt-5 pt-5 nk-block-middle nk-auth-body wide-sm">
      <div class="card w3-round-xlarge max-width700">
        <div class="card-inner">
          <div class="nk-block-head">
            <div class="brand-logo mt-0 pb-4 text-center">
              <a href="#" class="logo-link w3-block">
                <img
                  class="logo-light logo-img logo-img-lg"
                  src="images/logo.png"
                  srcset="images/logo2x.png 2x"
                  alt="logo"
                />
                <img
                  class="logo-dark logo-img logo-img-lg"
                  src="images/logo-dark.png"
                  srcset="images/logo-dark2x.png 2x"
                  alt="logo-dark"
                />
              </a>
            </div>
            <div class="nk-block-head-content">
              <h3 class="nk-block-title bold">
                Check out
                <span class="bold">{{ Settings._APP_NAME }}&trade;</span> — it’s
                free!
              </h3>
              <div class="nk-block-des">
                <p>
                  <span class="bold">{{ Settings._APP_NAME }}&trade;</span>
                  helps freelancers, consultants, and small businesses around
                  the world simplify their invoices.
                </p>
              </div>
            </div>
          </div>
          <form @submit.prevent="Register" action="">
            <div v-show="View === 'PersonalInfo'">
              <div class="text-center pt-0 mb-4">
                <h6 class="overline-title overline-title-sap">
                  <span>PERSONAL INFORMATION</span>
                </h6>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="firstname"
                        >First name <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <input
                      v-model="user.firstname"
                      type="text"
                      maxlength="100"
                      class="form-control form-control-lg"
                      id="firstname"
                      placeholder="First name"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="lastname"
                        >Last name <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <input
                      v-model="user.lastname"
                      type="text"
                      maxlength="100"
                      class="form-control form-control-lg"
                      id="lastname"
                      placeholder="Last name"
                    />
                  </div>
                </div>
                <div class="col-md-8 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="email"
                        >Email <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <input
                      v-model="user.email"
                      type="text"
                      maxlength="150"
                      class="form-control form-control-lg"
                      id="email"
                      placeholder="Enter your email address"
                    />
                    <p class="mt-1 size12">
                      This is your
                      <span class="bold">{{ Settings._APP_NAME }}&trade;</span>
                      Login ID
                    </p>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="password"
                        >Password</label
                      >
                    </div>
                    <div class="form-control-wrap">
                      <a
                        @click.prevent="Util.togglePassword"
                        href="#"
                        class="form-icon form-icon-right passcode-switch"
                        data-target="password"
                      >
                        <em class="passcode-icon icon-show icon ni ni-eye"></em>
                        <em
                          class="passcode-icon icon-hide icon ni ni-eye-off"
                        ></em>
                      </a>
                      <input
                        v-model="user.password"
                        type="password"
                        maxlength="150"
                        class="form-control form-control-lg"
                        id="password"
                        placeholder="Choose a Password"
                      />
                    </div>
                    <p class="mt-1 size12">At least 6 characters</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="View === 'MerchantInfo'">
              <div class="text-center pt-0 mb-4">
                <h6 class="overline-title overline-title-sap">
                  <span>MERCHANT INFORMATION</span>
                </h6>
              </div>
              <div class="row">
                <div class="col-md-12 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="name"
                        >Company name <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <input
                      v-model="merchant.company_name"
                      @keyup="MerchantID"
                      maxlength="150"
                      type="text"
                      class="form-control form-control-lg"
                      id="name"
                      placeholder="Company name"
                    />
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="merchant_id"
                        >Merchant ID <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <div class="w3-row">
                      <div
                        class="w3-col w3-cell-middle m4 ellipsis w3-right-align"
                      >
                        {{ Settings._FRONTEND_URL }}
                      </div>
                      <div class="w3-rest w3-cell-middle w3-mobile">
                        <div v-if="!EditSlug">
                          /{{ merchant.merchant_id
                          }}<i
                            @click="EditSlug = true"
                            class="fas fa-pencil-alt cursor ml-3"
                          ></i>
                        </div>
                        <div
                          v-if="EditSlug"
                          class="input-group input-group min-width150"
                        >
                          <div class="input-group-prepend">/</div>
                          <input
                            v-model="merchant.merchant_id"
                            @keyup="MerchantID"
                            type="text"
                            maxlength="150"
                            class="form-control form-control"
                            id="merchant_id"
                            placeholder="Enter unique Merchant ID"
                          />
                          <div class="input-group-append">
                            <button
                              @click.prevent="EditSlug = false"
                              class="btn btn w3-button w3-theme"
                            >
                              <i class="fas fa-check cursor"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="industry"
                        >What type of company do you own?
                        <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <div class="row">
                      <div class="col-md-6 mb-4">
                        <div class="custom-control custom-radio">
                          <input
                            v-model="merchant.company_type"
                            type="radio"
                            id="unregistered"
                            name="customRadio"
                            class="custom-control-input"
                            value="unregistered"
                          />
                          <label
                            class="custom-control-label w3-block"
                            for="unregistered"
                            >Unregistered Business
                            <p class="size13 w3-text-grey w3-block mt-3 mb-0">
                              I'm testing my ideas with real customers, and
                              preparing to register my company
                            </p></label
                          >
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div
                          class="custom-control custom-control-lg custom-radio"
                        >
                          <input
                            v-model="merchant.company_type"
                            type="radio"
                            id="registered"
                            name="customRadio"
                            class="custom-control-input"
                            value="registered"
                          />
                          <label class="custom-control-label" for="registered">
                            Registered Business
                            <p class="size13 w3-text-grey w3-block mt-3 mb-0">
                              My business has the approval, documentation, and
                              licences required to operate legally
                            </p></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="address"
                        >Business Address
                        <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <input
                      v-model="merchant.address"
                      type="text"
                      class="form-control form-control-lg"
                      id="address"
                      placeholder="Street address"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="country"
                        >Country <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <select
                      id="country"
                      v-model="merchant.country"
                      @change="GetStates"
                      class="form-control form-control-lg select2"
                      data-placeholder="Select country"
                      data-allow-clear="true"
                    >
                      <option
                        v-for="country in Settings._COUNTRIES"
                        :key="country.country_code"
                        :value="country.country_code"
                      >
                        {{ country.country_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="state"
                        >State <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <select
                      id="state"
                      v-model="merchant.state"
                      class="form-control form-control-lg select2"
                      data-placeholder="Select state"
                      data-allow-clear="true"
                    >
                      <option
                        v-for="state in States"
                        :key="state.state_code"
                        :value="state.state_code"
                      >
                        {{ state.state_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-8 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="city"
                        >City <span class="w3-text-red">*</span></label
                      >
                    </div>
                    <input
                      v-model="merchant.city"
                      type="text"
                      maxlength="150"
                      class="form-control form-control-lg"
                      id="city"
                      placeholder="City"
                    />
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label mb-1 bold" for="postcode"
                        >Zip/Postcode</label
                      >
                    </div>
                    <input
                      v-model="merchant.postcode"
                      type="text"
                      maxlength="10"
                      class="form-control form-control-lg"
                      id="postcode"
                      placeholder="Zip/Postcode"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-4">
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                    v-model="user.consent"
                  />
                  <label class="custom-control-label" for="customCheck1"
                    >I consent to the collection and processing of my personal
                    data in line with data regulations as described in the
                    <span class="bold">{{ Settings._APP_NAME }}&trade; </span>
                    <a @click.stop.prevent href="#">Privacy Policy</a>
                  </label>
                </div>
              </div>
            </div>
            <div v-show="View === 'PersonalInfo'" class="row mt-4">
              <div class="col-md mb-3">
                <div class="form-group">
                  <button
                    @click.prevent="GoTo('MerchantInfo')"
                    type="button"
                    class="w3-block w3-button w3-theme w3-hover-theme"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div v-show="View === 'MerchantInfo'" class="row mt-4">
              <div class="col-md mb-3">
                <div class="form-group">
                  <div class="button-group">
                    <button
                      @click.prevent="GoTo('PersonalInfo')"
                      class="w3-button w3-theme-l5 width25p"
                    >
                      Prev
                    </button>
                    <button
                      type="submit"
                      class="w3-button w3-theme w3-hover-theme width75p"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="form-note-s2 text-center pt-3">
            Already have an account?
            <router-link :to="{ name: 'login' }">Sign in now.</router-link>
          </div>
          <div class="text-center pt-4 pb-3">
            <h6 class="overline-title overline-title-sap">
              <span>OR SIGNUP WITH</span>
            </h6>
          </div>
          <ul class="nav justify-center gx-4">
            <li class="nav-item">
              <a class="nav-link" href="#">Facebook</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Google</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, computed, onBeforeMount, onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";
import { frontendUrl, Util } from "@/helpers/utilities";
import { loadScript } from "vue-plugin-load-script";
export default {
  compaonents: {},
  setup() {
    const store = useStore();
    const appTitle = computed(() => store.state.appTitle);
    const Settings = computed(() => store.getters.settings);
    console.log(Settings.value);
    const EditSlug = ref(false);
    const $ = window.$;
    const publicPath = frontendUrl;
    const user = ref({
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      role: "",
      consent: false,
    });
    const merchant = ref({
      merchant_id: "",
      company_name: "",
      company_type: "registered",
      address: "",
      city: "",
      state: "",
      country: "",
      postcode: "",
      timezone: "",
    });
    const View = ref("PersonalInfo"); // PersonalInfo, MerchantInfo
    const GoTo = (view) => {
      View.value = view;
    };
    const Register = () => {
      user.value.role = "owner";
      store.dispatch("users/auth_register", {
        user: user.value,
        merchant: merchant.value,
      });
    };
    const tempMerchantId = ref("");
    const MerchantID = ($event) => {
      tempMerchantId.value = $event.target.value;
      GenerateCompanyID();
    };
    const GenerateCompanyID = () => {
      let str = tempMerchantId.value.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
      var to =
        "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      merchant.value.merchant_id = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes
    };
    const States = computed(() => store.getters.states);
    const GetStates = () => {
      store.dispatch("states", {
        country: merchant.value.country,
      });
    };
    GetStates();
    onMounted(() => {
      loadScript(publicPath + "/vendor/jquery/jquery-3.6.0.min.js");
      loadScript(publicPath + "/vendor/select2/js/select2.full.min.js")
        .then(() => {
          const $country = $("#country").select2({ theme: "bootstrap" });
          const $state = $("#state").select2({ theme: "bootstrap" });
          $country
            .on("select2:select", function (e) {
              var data = e.params.data;
              merchant.value.country = data.id;
              GetStates();
            })
            .on("select2:unselect", function () {
              store.dispatch("states", {
                country: merchant.value.country,
              });
              $country.val(null).trigger("change");
              $state.val(null).trigger("change");
              merchant.value.country = "";
              merchant.value.state = "";
            });
          $state
            .on("select2:select", function (e) {
              var data = e.params.data;
              merchant.value.state = data.id;
            })
            .on("select2:unselect", function () {
              $state.val(null).trigger("change");
              merchant.value.state = "";
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
    onBeforeMount(() => {
      document.querySelector("body").classList.remove("has-apps-sidebar");
      document.querySelector("body").classList.remove("has-sidebar");
    });
    onBeforeUnmount(() => {
      document.querySelector("body").classList.add("has-sidebar");
      document.querySelector("body").classList.add("has-apps-sidebar");
    });
    return {
      Settings,
      Util,
      View,
      EditSlug,
      GoTo,
      Register,
      appTitle,
      user,
      merchant,
      MerchantID,
      States,
      GetStates,
    };
  },
};
</script>